// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function axios<T = iJrpcRet<any>>(
  url: string,
  config: {
    method: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: any;
  }
): Promise<T> {
  return fetch(url, {
    method: config.method,
    body: JSON.stringify(config.params),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((ret) => {
    return ret.json();
  });
}

export interface iJrpcRet<T> {
  id: number;
  error: null | string;
  result: T;
}
