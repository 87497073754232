import { axios } from ".";

const baseUrl = "/api";
// const baseUrl = 'http://127.0.0.1:8021'
export const expandApi = "/expandApi";
// export const yccApi = "http://13.115.171.240:7901";
export const yccApi = "/yccApi";

export function getNews(v: string) {
  return fetch(baseUrl + "/v1/article/index" + "?cid=8&language=" + v, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  }).then((ret) => {
    return ret.json();
  });
}

export function getNewsDetails(id: number, v: string) {
  return fetch(baseUrl + `/v1/article/view?id=${id}&language=${v}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  }).then((ret) => {
    return ret.json();
  });
}

export function getAccountRecords(
  addr: string,
  ranges: number[],
  symbol: string
) {
  const list = ranges.map((i) => {
    return {
      page: {
        size: 1,
        number: 1,
      },
      range: [
        {
          end: i,
          key: "block_time",
        },
      ],
      sort: [
        {
          ascending: false,
          key: "height_index",
        },
      ],
      match: [
        {
          key: "address",
          value: addr,
        },
        {
          key: "asset_exec",
          value: "coinsx",
        },
        {
          key: "asset_symbol",
          value: symbol,
        },
      ],
    };
  });

  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Account.AccountRecords",
      params: [list],
    },
  });
}

export function getTxCount() {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Tx.TxCount",
      params: [],
    },
  });
}

export function getTxList(number: number, size: number) {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Tx.TxList",
      params: [
        {
          sort: [
            {
              key: "height",
              ascending: false,
            },
          ],
          page: {
            number,
            size,
          },
        },
      ],
    },
  });
}


/**
 * 获取区块列表
 *
 * @export
 * @param {number} number
 * @param {number} size
 * @param {number} gt
 * @returns
 */
export function getBlocks(number: number, size: number, gt?: number) {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Block.BlockList",
      params: [{
        page: {
          size,
          number
        },
        range: [
          {
            gt,
            key: "tx_count"
          }
        ],
        sort: [
          {
            key: "height",
            ascending: false
          }
        ]
      }],
    },
  });
}

/**
 * 获取区块总数
 *
 * @export
 * @param {number} gt
 * @returns
 */
export function getBlocksCount(gt: number) {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Block.Count",
      params: [{
        range: [
          {
            gt,
            key: "tx_count"
          }
        ]
      }],
    },
  });
}

export function blockStatStatGets(startHeight: number, endHeight: number) {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "BlockStat.StatGets",
      params: [
        {
          height: [startHeight, endHeight],
        },
      ],
    },
  });
}

export function getAccountCount() {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Account.Count",
      params: [
        {
          match: [],
        },
      ],
    },
  });
}

export function statSearch(times: number[]) {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "BlockStat.StatSearches",
      params: [
        times.map((i) => {
          return {
            page: {
              size: 1,
              number: 1,
            },
            range: [
              {
                start: i,
                key: "time",
              },
            ],
            sort: [
              {
                ascending: true,
                key: "height",
              },
            ],
          };
        }),
      ],
    },
  });
}

export function getAddressTxCount(addr: string, matches = [] as any[]) {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Tx.TxCount",
      params: [
        {
          match_one: [
            {
              key: "from",
              value: addr,
            },
            {
              key: "to",
              value: addr,
            },
          ],
          match: matches,
        },
      ],
    },
  });
}

export function getAddressTxCountOrigin(params: any) {
  console.log(params, 123);

  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Tx.TxCount",
      params,
    },
  });
}

export function getAllPos33TicketCount() {
  return axios(yccApi, {
    method: "post",
    params: {
      method: "pos33.GetPos33Info",
      params: [{}],
    },
  });
}

export function getTxCountBewteenTimes(
  startUnixTime: number,
  endUnixTime: number
) {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Tx.TxCount",
      params: [
        {
          range: [
            {
              start: startUnixTime,
              end: endUnixTime,
              key: "block_time",
            },
          ],
        },
      ],
    },
  });
}

export function getAddrTxList(addr: string, number: number, size: number) {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Tx.TxList",
      params: [
        {
          match_one: [
            { key: "from", value: addr },
            { key: "to", value: addr },
          ],
          match: [],
          sort: [{ key: "height", ascending: false }],
          page: { number, size },
        },
      ],
    },
  });
}

export function getAddrTxList_another(
  number: number,
  size: number,
  objContainMatchOneAndMatch: any
) {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Tx.TxList",
      params: [
        {
          ...objContainMatchOneAndMatch,
          "not": [
            {
              "key": "action_name",
              "value": "miner"
            }
          ],
          sort: [{ key: "height", ascending: false }],
          page: { number, size },
        },
      ],
    },
  });
}

export function getMakerAddrCount(addr: string) {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Tx.TxCount",
      params: [
        {
          match: [
            {
              key: "maker_addr",
              value: addr,
            },
          ],
        },
      ],
    },
  });
}
export function getVoterAddrCount(addr: string) {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Tx.TxCount",
      params: [
        {
          match: [
            {
              key: "voter_addr",
              value: addr,
            },
            {
              "key": "action_name",
              "value": "miner"
            }
          ],
        },
      ],
    },
  });
}

export function getMakerAddr(addr: string, number: number, size: number) {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Tx.TxList",
      params: [
        {
          sort: [
            {
              key: "height",
              ascending: false,
            },
          ],
          match: [
            {
              key: "maker_addr",
              value: addr,
            },
          ],
          page: {
            number,
            size,
          },
        },
      ],
    },
  });
}

export function getVoterAddr(addr: string, number: number, size: number) {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Tx.TxList",
      params: [
        {
          sort: [
            {
              key: "height",
              ascending: false,
            },
          ],
          match: [
            {
              key: "voter_addr",
              value: addr,
            },
            {
              "key": "action_name",
              "value": "miner"
            }
          ],
          page: {
            number,
            size,
          },
        },
      ],
    },
  });
}

export function numOfOnChainTx(times: { start: number; end: number }[]) {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Tx.TxCounts",
      params: [
        [
          ...times.map((i) => ({
            range: [
              {
                start: i.start,
                end: i.end,
                key: "block_time",
              },
            ],
          })),
        ],
      ],
    },
  });
}

// 获取区块下非共识交易总数
export function getBlocksUnConsensusTxsCount(height: number) {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Tx.TxCount",
      params: [
        {
          "not": [
            {
              "key": "action_name",
              "value": "miner"
            }
          ],
          match: [
            {
              key: "height",
              value: height,
            }
          ],
        },
      ],
    },
  });
}

// 获取区块下非共识交易列表
export function getBlocksUnConsensusTxs(height: number, number: number, size: number) {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Tx.TxList",
      params: [
        {
          sort: [
            {
              key: "height",
              ascending: false,
            },
          ],
          "not": [
            {
              "key": "action_name",
              "value": "miner"
            }
          ],
          match: [
            {
              key: "height",
              value: height,
            }
          ],
          page: {
            number,
            size,
          },
        },
      ],
    },
  });
}

// 获取区块下共识交易总数
export function getBlocksConsensusTxsCount(height: number) {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Tx.TxCount",
      params: [
        {
          match: [
            {
              "key": "action_name",
              "value": "miner"
            },
            {
              key: "height",
              value: height,
            }
          ],
        },
      ],
    },
  });
}

// 获取区块下共识交易列表
export function getBlocksConsensusTxs(height: number, number: number, size: number) {
  return axios(expandApi, {
    method: "post",
    params: {
      id: 1,
      method: "Tx.TxList",
      params: [
        {
          sort: [
            {
              key: "height",
              ascending: false,
            },
          ],
          match: [
            {
              "key": "action_name",
              "value": "miner"
            },
            {
              key: "height",
              value: height,
            }
          ],
          page: {
            number,
            size,
          },
        },
      ],
    },
  });
}